// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-post{
    background-color:rgba(0,0,0,.05);
    margin-top:30px;
    height:500px;

}

.event-post:hover{
    background-color:rgba(0,0,0,.1);
    width: 110%;
}`, "",{"version":3,"sources":["webpack://./src/components/events/events.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,eAAe;IACf,YAAY;;AAEhB;;AAEA;IACI,+BAA+B;IAC/B,WAAW;AACf","sourcesContent":[".event-post{\n    background-color:rgba(0,0,0,.05);\n    margin-top:30px;\n    height:500px;\n\n}\n\n.event-post:hover{\n    background-color:rgba(0,0,0,.1);\n    width: 110%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
