// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery-box{
    border-top: 1px solid rgba(0,0,0,.1);;
    margin-top:50px;
    min-height:500px;
    margin-left:150px;
    margin-right:150px;
    margin-bottom:50px;
    }

.image-div{
    margin: 5px;
    border: 1px solid #ccc;
    float: left;
    width: 180px;
    height:140px;



}

.image-div img{
    width: 180px;
    height:140px;
    max-width: inherit;
    max-height: inherit;
}`, "",{"version":3,"sources":["webpack://./src/components/Gallery/post-gallery.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;IAClB;;AAEJ;IACI,WAAW;IACX,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,YAAY;;;;AAIhB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".gallery-box{\n    border-top: 1px solid rgba(0,0,0,.1);;\n    margin-top:50px;\n    min-height:500px;\n    margin-left:150px;\n    margin-right:150px;\n    margin-bottom:50px;\n    }\n\n.image-div{\n    margin: 5px;\n    border: 1px solid #ccc;\n    float: left;\n    width: 180px;\n    height:140px;\n\n\n\n}\n\n.image-div img{\n    width: 180px;\n    height:140px;\n    max-width: inherit;\n    max-height: inherit;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
