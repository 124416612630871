// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zegar-diw{
    background-color:#F4F6F6;
    height: 100px;
    width: 400px;
    text-shadow: ;
    box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12)
  ;
   border-radius:50px;
   position: fixed;
   top: 30%;
   left: 46.5%;
   transform: translate(-50%, -50%);
   transition: background-color 1s ease-out;

}
.zegar-diw:hover{
    background-color: powderblue;
}`, "",{"version":3,"sources":["webpack://./src/components/Addons/clock.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,aAAa;IACb,YAAY;IACZ,aAAa;IACb;;;;;;;EAOF;GACC,kBAAkB;GAClB,eAAe;GACf,QAAQ;GACR,WAAW;GAEX,gCAAgC;GAIhC,wCAAwC;;AAE3C;AACA;IACI,4BAA4B;AAChC","sourcesContent":[".zegar-diw{\n    background-color:#F4F6F6;\n    height: 100px;\n    width: 400px;\n    text-shadow: ;\n    box-shadow:\n    0 2.8px 2.2px rgba(0, 0, 0, 0.034),\n    0 6.7px 5.3px rgba(0, 0, 0, 0.048),\n    0 12.5px 10px rgba(0, 0, 0, 0.06),\n    0 22.3px 17.9px rgba(0, 0, 0, 0.072),\n    0 41.8px 33.4px rgba(0, 0, 0, 0.086),\n    0 100px 80px rgba(0, 0, 0, 0.12)\n  ;\n   border-radius:50px;\n   position: fixed;\n   top: 30%;\n   left: 46.5%;\n   -webkit-transform: translate(-50%, -50%);\n   transform: translate(-50%, -50%);\n   -webkit-transition: background-color 1s ease-out;\n   -moz-transition: background-color 1s ease-out;\n   -o-transition: background-color 1s ease-out;\n   transition: background-color 1s ease-out;\n\n}\n.zegar-diw:hover{\n    background-color: powderblue;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
