// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h4{
    display:inline;
    margin:30px;
    margin-bottom: 50px;
   
    
}


.comments{
    left:50%;
    right:50%;
    

}

.user{
   margin-right: 80px;
}
.content{
    margin-right: 80px;
    margin-left:94px;
    margin-bottom: 20px;
}
.comment-box{
margin-top:40px;
}

.comment-box h5{
    border-bottom:1px solid rgba(0,0,0,.2);
    width:300px;
    left:50%;
    right: 50%;
    
}

.comment-box h6{
    margin-top:5px;
}`, "",{"version":3,"sources":["webpack://./src/components/comments/comment.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,WAAW;IACX,mBAAmB;;;AAGvB;;;AAGA;IACI,QAAQ;IACR,SAAS;;;AAGb;;AAEA;GACG,kBAAkB;AACrB;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;AACA;AACA,eAAe;AACf;;AAEA;IACI,sCAAsC;IACtC,WAAW;IACX,QAAQ;IACR,UAAU;;AAEd;;AAEA;IACI,cAAc;AAClB","sourcesContent":["h4{\n    display:inline;\n    margin:30px;\n    margin-bottom: 50px;\n   \n    \n}\n\n\n.comments{\n    left:50%;\n    right:50%;\n    \n\n}\n\n.user{\n   margin-right: 80px;\n}\n.content{\n    margin-right: 80px;\n    margin-left:94px;\n    margin-bottom: 20px;\n}\n.comment-box{\nmargin-top:40px;\n}\n\n.comment-box h5{\n    border-bottom:1px solid rgba(0,0,0,.2);\n    width:300px;\n    left:50%;\n    right: 50%;\n    \n}\n\n.comment-box h6{\n    margin-top:5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
