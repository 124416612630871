// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


.left{
   float: left;
   width: 60%;
   position: absolute;
}


.right{
    margin-top:40px;
    float:right;
    width:40%;
    

}


.blog-diw2{
    position: inherit;
    border-radius:1px;
    height: 500px;
    width: inherit;
    max-width: inherit;
    max-height: 500px;
    margin-bottom: 30px;
    margin-left:40px;
    border-bottom:1px solid rgba(0,0,0,.1);
    background-color: rgba(0,0,0,.05);
    color:black;
}

.blog-diw2 > *{
    color:inherit;
}

.blog-diw2 a{
    color:inherit;
}


.blog-diw2:hover{
    background-color: rgba(0,0,0,.1);
}



a:hover, a:active {
    text-decoration: none;
    color: black; 
  }


.content-diw2{
    height:10px;
    text-align:center;
    margin:40px;
 
}
.blog-diw2 img{
    
    height: 40%;
    max-width:50%;
    max-height: 280px;
}
`, "",{"version":3,"sources":["webpack://./src/components/blog/wyglad.css"],"names":[],"mappings":";;;AAGA;GACG,WAAW;GACX,UAAU;GACV,kBAAkB;AACrB;;;AAGA;IACI,eAAe;IACf,WAAW;IACX,SAAS;;;AAGb;;;AAGA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,aAAa;IACb,cAAc;IACd,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,sCAAsC;IACtC,iCAAiC;IACjC,WAAW;AACf;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;;AAGA;IACI,gCAAgC;AACpC;;;;AAIA;IACI,qBAAqB;IACrB,YAAY;EACd;;;AAGF;IACI,WAAW;IACX,iBAAiB;IACjB,WAAW;;AAEf;AACA;;IAEI,WAAW;IACX,aAAa;IACb,iBAAiB;AACrB","sourcesContent":["\n\n\n.left{\n   float: left;\n   width: 60%;\n   position: absolute;\n}\n\n\n.right{\n    margin-top:40px;\n    float:right;\n    width:40%;\n    \n\n}\n\n\n.blog-diw2{\n    position: inherit;\n    border-radius:1px;\n    height: 500px;\n    width: inherit;\n    max-width: inherit;\n    max-height: 500px;\n    margin-bottom: 30px;\n    margin-left:40px;\n    border-bottom:1px solid rgba(0,0,0,.1);\n    background-color: rgba(0,0,0,.05);\n    color:black;\n}\n\n.blog-diw2 > *{\n    color:inherit;\n}\n\n.blog-diw2 a{\n    color:inherit;\n}\n\n\n.blog-diw2:hover{\n    background-color: rgba(0,0,0,.1);\n}\n\n\n\na:hover, a:active {\n    text-decoration: none;\n    color: black; \n  }\n\n\n.content-diw2{\n    height:10px;\n    text-align:center;\n    margin:40px;\n \n}\n.blog-diw2 img{\n    \n    height: 40%;\n    max-width:50%;\n    max-height: 280px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
