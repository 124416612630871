// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.parent{
  display: table;
  position: flex;
  left: 100px;
}

div.gallery {
    display: table-cell;
    margin: 5px;
    border: 1px solid #ccc;
    float: left;
    width: 300px;
    height: 140px;
 

  }
  
  div.gallery:hover {
    border: 2px solid #777;
  }
  
  div.gallery img {
    /*width: 100% ;*/
    height: 100%;
  }
  
  div.desc {
    padding: 15px;
    text-align: center;
  }
`, "",{"version":3,"sources":["webpack://./src/components/Gallery/galer.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,cAAc;EACd,WAAW;AACb;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,aAAa;;;EAGf;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,gBAAgB;IAChB,YAAY;EACd;;EAEA;IACE,aAAa;IACb,kBAAkB;EACpB","sourcesContent":[".parent{\n  display: table;\n  position: flex;\n  left: 100px;\n}\n\ndiv.gallery {\n    display: table-cell;\n    margin: 5px;\n    border: 1px solid #ccc;\n    float: left;\n    width: 300px;\n    height: 140px;\n \n\n  }\n  \n  div.gallery:hover {\n    border: 2px solid #777;\n  }\n  \n  div.gallery img {\n    /*width: 100% ;*/\n    height: 100%;\n  }\n  \n  div.desc {\n    padding: 15px;\n    text-align: center;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
