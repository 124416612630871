// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#shadow-add{
    box-shadow:         1px 1px 1px 1px #ccc;
}

#rozmiar{
    width:900px;
}


.tags-left{
    width : 50%;
    float:left;
}

.tags-right{
    width : 50%;
    float:right;
}`, "",{"version":3,"sources":["webpack://./src/components/blog/add.css"],"names":[],"mappings":"AAAA;IAGI,wCAAwC;AAC5C;;AAEA;IACI,WAAW;AACf;;;AAGA;IACI,WAAW;IACX,UAAU;AACd;;AAEA;IACI,WAAW;IACX,WAAW;AACf","sourcesContent":["#shadow-add{\n    -moz-box-shadow:    1px 1px 1px 1px #ccc;\n    -webkit-box-shadow: 1px 1px 1px 1px #ccc;\n    box-shadow:         1px 1px 1px 1px #ccc;\n}\n\n#rozmiar{\n    width:900px;\n}\n\n\n.tags-left{\n    width : 50%;\n    float:left;\n}\n\n.tags-right{\n    width : 50%;\n    float:right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
