// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disp{
    background-color:#F4F6F6;
    max-width: 100%;
    margin:20px;
    left:8%;
    transition: background-color 2s ease-out;
    min-height:700px;
    max-width: 1200;
}

body{
    overflow-y:scroll;
}
.disp:hover{
  background-color: rgba(0,0,0,.08);
}


h3{
    margin:30px;
}

p{
    text-align:center;
    margin:40px;
    margin-bottom: 40px;
}


.comm{
    margin: 80px;
    
}


div.gallery {
    margin: 5px;
    border: 1px solid #ccc;
    float: left;
    width: 180px;
  }
  
  div.gallery:hover {
    border: 1px solid #777;
  }
  
  div.gallery img {
    /*width: 100%;*/
    height: auto;
  }
  
  div.desc {
    padding: 15px;
    text-align: center;
  }


.display-content{
  min-height:400px;

}

`, "",{"version":3,"sources":["webpack://./src/components/blog/post.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,eAAe;IACf,WAAW;IACX,OAAO;IAIP,wCAAwC;IACxC,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;AACA;EACE,iCAAiC;AACnC;;;AAGA;IACI,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,mBAAmB;AACvB;;;AAGA;IACI,YAAY;;AAEhB;;;AAGA;IACI,WAAW;IACX,sBAAsB;IACtB,WAAW;IACX,YAAY;EACd;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,eAAe;IACf,YAAY;EACd;;EAEA;IACE,aAAa;IACb,kBAAkB;EACpB;;;AAGF;EACE,gBAAgB;;AAElB","sourcesContent":[".disp{\n    background-color:#F4F6F6;\n    max-width: 100%;\n    margin:20px;\n    left:8%;\n    -webkit-transition: background-color 2s ease-out;\n    -moz-transition: background-color 2s ease-out;\n    -o-transition: background-color 2s ease-out;\n    transition: background-color 2s ease-out;\n    min-height:700px;\n    max-width: 1200;\n}\n\nbody{\n    overflow-y:scroll;\n}\n.disp:hover{\n  background-color: rgba(0,0,0,.08);\n}\n\n\nh3{\n    margin:30px;\n}\n\np{\n    text-align:center;\n    margin:40px;\n    margin-bottom: 40px;\n}\n\n\n.comm{\n    margin: 80px;\n    \n}\n\n\ndiv.gallery {\n    margin: 5px;\n    border: 1px solid #ccc;\n    float: left;\n    width: 180px;\n  }\n  \n  div.gallery:hover {\n    border: 1px solid #777;\n  }\n  \n  div.gallery img {\n    /*width: 100%;*/\n    height: auto;\n  }\n  \n  div.desc {\n    padding: 15px;\n    text-align: center;\n  }\n\n\n.display-content{\n  min-height:400px;\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
