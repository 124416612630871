// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `




.event-post:hover{
    background-color:rgba(0,0,0,.1);
    width: 110%;
}


.tag {
    display: inline-block;
    border-radius: 3px;
    padding: .2em .5em .3em;
    border-radius: 2px;
    background: var(--tag-bg);
    color: var(--text-color);
    font-weight: 600;
    margin: .25em .1em
}



.tag-lg {
    font-size: 1.2em;
    border-radius: 4px
}


.tag-cloud-functions {
    background: var(--blue);
    color: #fff
}

`, "",{"version":3,"sources":["webpack://./src/components/blog/tag.css"],"names":[],"mappings":";;;;;AAKA;IACI,+BAA+B;IAC/B,WAAW;AACf;;;AAGA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,uBAAuB;IACvB,kBAAkB;IAClB,yBAAyB;IACzB,wBAAwB;IACxB,gBAAgB;IAChB;AACJ;;;;AAIA;IACI,gBAAgB;IAChB;AACJ;;;AAGA;IACI,uBAAuB;IACvB;AACJ","sourcesContent":["\n\n\n\n\n.event-post:hover{\n    background-color:rgba(0,0,0,.1);\n    width: 110%;\n}\n\n\n.tag {\n    display: inline-block;\n    border-radius: 3px;\n    padding: .2em .5em .3em;\n    border-radius: 2px;\n    background: var(--tag-bg);\n    color: var(--text-color);\n    font-weight: 600;\n    margin: .25em .1em\n}\n\n\n\n.tag-lg {\n    font-size: 1.2em;\n    border-radius: 4px\n}\n\n\n.tag-cloud-functions {\n    background: var(--blue);\n    color: #fff\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
