// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.obramowanie-latest{
    margin-top:60px;
    left: 10%;
    width: 80%;
    margin-left: 40px;
    border:1px solid rgba(0,0,0,.1);
    border-radius: 10px;
    height: 350px;
    max-width: inherit;
    min-width: inherit;
    position: flex;
   

}
.obramowanie-latest h5{
    border-bottom:1px solid rgba(0,0,0,.2);
    padding: 0 0 10px;
    border-left:5px
 
}



.linijka-post-obramowanie{
    width: inherit;
    height: 10px;
    max-width: inherit;
    min-width: inherit;
    position: flex;


}

.linijka-post-obramowanie a{
    color:black;
}
.linijka-post-obramowanie p{
    margin-left : 40px;
    margin-right : 40px;
    height : 40px;    
    text-align: left;
    position: inherit;

}
.linijka-post-obramowanie p:hover{
    background-color: rgba(0,0,0,.2);;
}



`, "",{"version":3,"sources":["webpack://./src/components/blog/wyglad-boxow.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,SAAS;IACT,UAAU;IACV,iBAAiB;IACjB,+BAA+B;IAC/B,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,kBAAkB;IAClB,cAAc;;;AAGlB;AACA;IACI,sCAAsC;IACtC,iBAAiB;IACjB;;AAEJ;;;;AAIA;IACI,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,cAAc;;;AAGlB;;AAEA;IACI,WAAW;AACf;AACA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,gBAAgB;IAChB,iBAAiB;;AAErB;AACA;IACI,gCAAgC;AACpC","sourcesContent":[".obramowanie-latest{\n    margin-top:60px;\n    left: 10%;\n    width: 80%;\n    margin-left: 40px;\n    border:1px solid rgba(0,0,0,.1);\n    border-radius: 10px;\n    height: 350px;\n    max-width: inherit;\n    min-width: inherit;\n    position: flex;\n   \n\n}\n.obramowanie-latest h5{\n    border-bottom:1px solid rgba(0,0,0,.2);\n    padding: 0 0 10px;\n    border-left:5px\n \n}\n\n\n\n.linijka-post-obramowanie{\n    width: inherit;\n    height: 10px;\n    max-width: inherit;\n    min-width: inherit;\n    position: flex;\n\n\n}\n\n.linijka-post-obramowanie a{\n    color:black;\n}\n.linijka-post-obramowanie p{\n    margin-left : 40px;\n    margin-right : 40px;\n    height : 40px;    \n    text-align: left;\n    position: inherit;\n\n}\n.linijka-post-obramowanie p:hover{\n    background-color: rgba(0,0,0,.2);;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
